import axios from "axios";
import { ServerDbUtils } from "@db_ui/db_utils";
import AuthService from "../AuthService";
const { ServerUtils } = ServerDbUtils;

const sessionStorageItems = {
    idToken: "id_token",
    expiredSessionTime: "$draw-client-expire-sessionTime",
};

export const validateSessionApi = () => {
    const as = new AuthService();
    const URL = ServerUtils.apiBaseUrlProd() + "/auths2/validateSession";
    const loginInfo = JSON.parse(sessionStorage.getItem("login_info"));
    const sessionOwner = loginInfo?.username;
    const isSessionTimeCurrentlyStored = sessionStorage.getItem(sessionStorageItems.expiredSessionTime);

    axios
        .post(URL, { token: as.getToken(), username: sessionOwner })
        .then((response) => {
            const responseData = response.data;
            if (responseData.code === 0) {
                if (responseData.token) {
                    as.setToken(responseData.token);
                }
                if (!isSessionTimeCurrentlyStored || isSessionTimeCurrentlyStored !== responseData.result) {
                    sessionStorage.setItem(sessionStorageItems.expiredSessionTime, responseData.result);
                }
                return responseData.result;
            } else {
                sessionStorage.removeItem(sessionStorageItems.expiredSessionTime);
                as.logout();
            }
        })
        .catch((error) => {
            sessionStorage.removeItem(sessionStorageItems.expiredSessionTime);
            as.logout();
        });
}

export const sessionLogoutApi = () => {
    const as = new AuthService();
    sessionStorage.removeItem(sessionStorageItems.expiredSessionTime);
    as.logout();
};

export const updateSessionApi = () => {
    const as = new AuthService();
    const URL = ServerUtils.apiBaseUrlProd() + "/auths2/generateJWT";
    const loginInfo = JSON.parse(sessionStorage.getItem("login_info"));
    const sessionOwner = loginInfo?.username;

    axios.post(URL, { token: as.getToken(), username: sessionOwner }).then((response) => {
        as.setToken(response.data.token);
        sessionStorage.removeItem(sessionStorageItems.expiredSessionTime);
        validateSessionApi();
    }).catch((error) => {
        sessionStorage.removeItem(sessionStorageItems.expiredSessionTime);
        as.logout();
    });
}
