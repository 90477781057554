import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import "@ag-grid-community/core/dist/styles/ag-theme-material.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";

//service worker
import * as serviceWorker from "./serviceWorker";

window.db = {
  store: {},
  config: {},
  env: window.env
};

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
